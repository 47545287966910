import { MyPageContext } from 'kp';

import redirectRequest from './redirectRequest';

type RedirectToCorrectUrlProps = {
  ctx: MyPageContext;
  url: string;
  correctUrl: string;
};

export const redirectToCorrectUrl = ({
  ctx,
  url,
  correctUrl,
}: RedirectToCorrectUrlProps) => {
  const { res } = ctx;
  if (!res) return;
  if (!correctUrl) return;
  if (url.split(/[?#]/)[0].toLowerCase() !== correctUrl.toLowerCase()) {
    redirectRequest({ ctx, url: correctUrl });
  }
};

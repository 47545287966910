/* eslint-disable consistent-return */
import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { getMetaTags } from '@lib/metaTags/getMetaTags';
import { API_URL } from 'src/lib/constants/env.constants';

import {
  AllUserAdsRoute,
  CategoryNewestRoute,
  CategorySearchRoute,
  GroupRoute,
  GroupSearchRoute,
  NewestSearchRoute,
  SearchRoute,
} from 'server/routes';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectCategoryName } from '@store/category/selector';
import { selectGroupAdClass, selectGroupName } from '@store/group/selector';
import { selectIsMobile } from '@store/meta/selector';
import { setPageType } from '@store/meta/slice';
import { selectTotalAdsCount } from '@store/search/selector';
import { selectUserSummary } from '@store/user/selector';

const SearchListNewestPage = dynamic(
  () => import('@pages/desktop/SearchListPage/SearchListNewestPage')
);
const SearchListNewestPageMobile = dynamic(
  () => import('@pages/mobile/SearchListPageMobile/SearchListNewestPageMobile')
);
const SearchListPage = dynamic(
  () => import('@pages/desktop/SearchListPage/SearchListPage')
);
const SearchListPageMobile = dynamic(
  () => import('@pages/mobile/SearchListPageMobile/SearchListPageMobile')
);
const SearchListCategoryPage = dynamic(
  () => import('@pages/desktop/SearchListPage/SearchListCategoryPage')
);
const SearchListCategoryPageMobile = dynamic(
  () =>
    import('@pages/mobile/SearchListPageMobile/SearchListCategoryPageMobile')
);

const SearchListCategoryNewestPage = dynamic(
  () => import('@pages/desktop/SearchListPage/SearchListCategoryNewestPage')
);

const SearchListCategoryNewestPageMobile = dynamic(
  () =>
    import(
      '@pages/mobile/SearchListPageMobile/SearchListCategoryNewestPageMobile'
    )
);

const SearchListGroupSearchPage = dynamic(
  () => import('@pages/desktop/SearchListPage/SearchListGroupSearchPage')
);
const SearchListGroupSearchPageMobile = dynamic(
  () =>
    import('@pages/mobile/SearchListPageMobile/SearchListGroupSearchPageMobile')
);
const SearchListGroupPage = dynamic(
  () => import('@pages/desktop/SearchListPage/SearchListGroupPage')
);

const SearchListGroupPageMobile = dynamic(
  () => import('@pages/mobile/SearchListPageMobile/SearchListGroupPageMobile')
);
const SearchListAllUserAdsPage = dynamic(
  () => import('@pages/desktop/SearchListPage/SearchListAllUserAdsPage')
);

const SearchListAllUsersAdsPageMobile = dynamic(
  () =>
    import('@pages/mobile/SearchListPageMobile/SearchListAllUsersAdsPageMobile')
);

const GROUP_PAGE = {
  job: 'group-job',
  basic: 'group',
};

type SearchRouteType = {
  route: {
    isMatch: (asPath: string) => boolean;
  };
  page: any;
  pageType: string;
  metaTags: any;
  breadcrumbSchemaData?: any[];
};

const useFoundSearchRoute = (): SearchRouteType => {
  const { query, asPath } = useRouter();
  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(selectIsMobile);

  const {
    categoryId,
    groupId,
    firstParam,
    group,
    page = 1,
    keywords,
    so,
  } = query;

  const categoryName = useAppSelector((state) =>
    selectCategoryName(state, categoryId)
  );
  const groupName = useAppSelector((state) =>
    selectGroupName(state, { categoryId, groupId })
  );
  const groupAdClass = useAppSelector((state) =>
    selectGroupAdClass(state, { categoryId, groupId })
  );
  const { username } = useAppSelector(selectUserSummary);
  const totalAdsCount = useAppSelector(selectTotalAdsCount);

  // category and group url for breadcrumb ld schema
  const categoryUrl = `${API_URL}/${firstParam}/kategorija/${categoryId}`;
  const groupUrl = `${API_URL}/${firstParam}/${group}/grupa/${categoryId}/${groupId}/${page}`;

  const searchRoutes = [
    {
      route: AllUserAdsRoute,
      page: isMobile
        ? SearchListAllUsersAdsPageMobile
        : SearchListAllUserAdsPage,
      pageType: 'allUserAds',
      metaTags: getMetaTags('allUserAds', { username }),
    },
    {
      route: SearchRoute,
      page: isMobile ? SearchListPageMobile : SearchListPage,
      pageType: 'search',
      metaTags: getMetaTags('search', {
        so,
        keywords,
      }),
      breadcrumbSchemaData: [
        { url: `${API_URL}`, name: 'Početna' },
        { url: '', name: 'Pretraga' },
        {
          url: '',
          name: `${totalAdsCount} rezultata ${
            query.keywords && query.keywords
          }`, // dodati zA
        },
      ],
    },
    {
      route: CategorySearchRoute,
      page: isMobile ? SearchListCategoryPageMobile : SearchListCategoryPage,
      pageType: 'search',
      metaTags: getMetaTags('search-category', {
        totalAdsCount,
        categoryName,
        so,
        keywords,
      }),
      breadcrumbSchemaData: [
        { url: `${API_URL}`, name: 'Početna' },
        { url: `${categoryUrl}`, name: `${categoryName}` },
        { url: '', name: `${totalAdsCount} rezultata` },
      ],
    },
    {
      route: GroupSearchRoute,
      page: isMobile
        ? SearchListGroupSearchPageMobile
        : SearchListGroupSearchPage,
      pageType: 'search',
      metaTags: getMetaTags(GROUP_PAGE[groupAdClass] ?? 'group', {
        totalAdsCount,
        groupName,
        categoryId,
        categoryName,
        so,
        keywords,
        groupId,
      }),
      breadcrumbSchemaData: [
        { url: `${API_URL}`, name: 'Početna' },
        { url: `${categoryUrl}`, name: `${categoryName}` },
        { url: `${groupUrl}`, name: `${groupName}` },
        { url: '', name: `${totalAdsCount} rezultata` },
      ],
    },
    {
      route: GroupRoute,
      page: isMobile ? SearchListGroupPageMobile : SearchListGroupPage,
      pageType: 'group',
      metaTags: getMetaTags(GROUP_PAGE[groupAdClass] ?? 'group', {
        totalAdsCount,
        groupName,
        categoryId,
        categoryName,
        groupId,
      }),
      breadcrumbSchemaData: [
        { url: `${API_URL}`, name: 'Početna' },
        { url: `${categoryUrl}`, name: `${categoryName}` },
        { url: `${groupUrl}`, name: `${groupName}` },
        { url: '', name: `${totalAdsCount} rezultata` },
      ],
    },
    {
      route: NewestSearchRoute,
      page: isMobile ? SearchListNewestPageMobile : SearchListNewestPage,
      pageType: 'search',
      metaTags: getMetaTags('search-newest'),
      breadcrumbSchemaData: [
        { url: `${API_URL}`, name: 'Početna' },
        { url: '', name: 'Najnoviji oglasi u poslednja 24h' },
        { url: '', name: `${totalAdsCount} rezultata` },
      ],
    },
    {
      route: CategoryNewestRoute,
      page: isMobile
        ? SearchListCategoryNewestPageMobile
        : SearchListCategoryNewestPage,
      pageType: 'search',
      metaTags: getMetaTags('search-category', {
        totalAdsCount,
        categoryName,
        so,
        keywords,
      }),
      breadcrumbSchemaData: [
        { url: `${API_URL}`, name: 'Početna' },
        { url: `${categoryUrl}`, name: `${categoryName}` },
        { url: '', name: `${totalAdsCount} rezultata` },
      ],
    },
  ];

  const foundRoute = searchRoutes.find((el) => el?.route?.isMatch(asPath));

  useEffect(() => {
    dispatch(setPageType(foundRoute?.pageType));
  }, [dispatch, foundRoute?.pageType]);

  return foundRoute ?? ({} as SearchRouteType);
};

export default useFoundSearchRoute;

import React, { PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import { compose } from 'redux';
import { MyAppInitialProps, MyAppProps, MyPageContext } from 'kp';

import isBotOrExtension from '@lib/isBotOrExtension';
import { redirectToCorrectUrl } from '@lib/redirectToCorrectUrl';

import { withAuthSyncComponent } from '@hocs/withAuthSync';
import { withPrefetchComponent } from '@hocs/withPrefetch';

import useBanner from '@hooks/banner/useBanner';
import { useAppSelector } from '@hooks/redux/useAppSelector';
import useFoundSearchRoute from '@hooks/search/useFoundSearchRoute';
import usePageCanonical from '@hooks/usePageCanonical';

import { handleAccessLoginControlOnServerSide } from '@store/api/request/handleAccessLoginControl';
import { fetchCarValue } from '@store/customValue/thunk';
import { Any } from '@store/global/type';
import { fetchCarModels, fetchGroups } from '@store/group/thunk';
import { setPageType } from '@store/meta/slice';
import { selectTotalAdsCount } from '@store/search/selector';
import { fetchSearchedAds } from '@store/search/thunk';
import { SearchPageQuery } from '@store/search/type';
import { fetchUserSummary } from '@store/user/thunk';
import { wrapper } from 'src/store/configureStore';

import { mainLayout } from '@layouts/desktop/ThreeColumnLayout/layout';
import { mobileMainLayout } from '@layouts/mobile/MobileMainLayout/layout';

import Seo from '@components/seo/responsive/Seo';
import ItemListSchemaLd from '@components/seo/responsive/seo-schemas/ItemListSchemaLd';

const SearchPageContainer = ({ isMobile }: MyAppInitialProps) => {
  const { query } = useRouter();
  const { keywords = '', saved = '' } = query as SearchPageQuery;
  const totalAdsCount = useAppSelector(selectTotalAdsCount);

  const foundRoute = useFoundSearchRoute();
  const { getAllUsersAdsPageCanonical } = usePageCanonical();
  const canonical = getAllUsersAdsPageCanonical();
  const {
    title = '',
    description = '',
    ogImage = '/static/images/meta/original.png',
  } = foundRoute?.metaTags ?? {};

  useBanner({
    page: 'search',
    variable: { isMobile, keywords, saved },
  });

  return (
    <>
      <Seo
        title={title}
        description={description}
        openGraph={{ title, description, images: [{ url: ogImage }] }}
        canonical={canonical}
      />
      {Number(totalAdsCount) > 0 && <ItemListSchemaLd />}
      <foundRoute.page route={foundRoute.route} />
    </>
  );
};

SearchPageContainer.getLayout = mainLayout;
SearchPageContainer.getLayoutMobile = (
  page: PropsWithChildren<{ props: MyAppProps }>
) =>
  mobileMainLayout({
    ...page,
    props: {
      ...page.props,
      isSearchPage: false,
      withFooter: true,
      withShareButton: true,
      withFollowButton: true,
      withBackButton: true,
      isHeaderFixed: false,
    },
  });

SearchPageContainer.getInitialProps = wrapper.getInitialAppProps(
  (store) => async (ctx: MyPageContext) => {
    const { query, req, res, asPath } = ctx;
    const { userId, categoryId, groupId } = query as SearchPageQuery;

    store.dispatch(setPageType('allUserAds'));

    if (req) {
      await store.dispatch(fetchSearchedAds({ query })).then((payload) => {
        handleAccessLoginControlOnServerSide({
          payload,
          store,
          ctx,
        });

        const hasShowAdsLinkDisabledError = payload?.payload?.errors?.some(
          (error: { error_code: string }) =>
            error.error_code === 'show_ads_link_disabled'
        );

        if (hasShowAdsLinkDisabledError) {
          res.statusCode = 410;
          (res as Any).redirect('/');
        }
      });
      if (categoryId === '2013') {
        await store.dispatch(fetchCarValue());
        if (groupId) await store.dispatch(fetchCarModels(groupId));
      }
      if (categoryId && groupId) {
        await store.dispatch(fetchGroups({ categoryId }));
      }
      const isBot = isBotOrExtension(req);
      const data: Any = await store.dispatch(fetchUserSummary({ userId }));
      const url = data?.payload?.user_ads_url ?? '';

      if (isBot && data?.payload?.user_active_ad_count === 0) {
        res.statusCode = 410;
        return { pageProps: {} };
      }

      if (url) {
        redirectToCorrectUrl({ ctx, url: asPath, correctUrl: url });
      }

      return { pageProps: {} };
    }

    if (categoryId === '2013') {
      store.dispatch(fetchCarValue());
      if (groupId) store.dispatch(fetchCarModels(groupId));
    }
    store.dispatch(fetchUserSummary({ userId }));
    store.dispatch(fetchSearchedAds({ query }));

    return { pageProps: {} };
  }
);
export default compose(
  withAuthSyncComponent,
  withPrefetchComponent
)(SearchPageContainer);
